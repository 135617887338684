import Button from "@/app/pwa/components/Button";
import Bugsnag from "@bugsnag/js";
import {useEffect} from "react";
import NotFound from "../pwa/not-found";

export default function ErrorPage({
                                    error,
                                    buttonClick,
                                    title,
                                    text,
                                    buttonText
                                  }: {
  error: Error
  buttonClick: () => void
  title?: React.ReactNode | string
  text?: React.ReactNode | string
  buttonText?: React.ReactNode | string
}){

  useEffect(() => {
    Bugsnag.notify(error);
  }, [error]);

  // if(error.message === "Not Found"){
  //   return NotFound();
  // }

  return (
    <div className="flex justify-center items-center text-center h-[50dvh] max-w-basic-page">
      <div className="space-y-4">
        <h2 className="">{title || "Leider ist ein Fehler aufgetreten"}</h2>
        {text &&
          <div>
            {text}
          </div>
        }
        <Button color="primary" onClick={() => buttonClick()}>{buttonText || "Nochmal versuchen"}</Button>
      </div>
    </div>
  )
}
